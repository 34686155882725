import React, { useEffect } from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ProfilePage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const [icon, setIcon] = React.useState(null);
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  useEffect(() => {
    const split = props.id.split('_');
    if (split.length > 1) {
      const importFns = {
        "ci": () => import('react-icons/ci'),
        "fa": () => import('react-icons/fa'),
        "fa6": () => import('react-icons/fa6'),
        "io": () => import('react-icons/io'),
        "io5": () => import('react-icons/io5'),
        "md": () => import('react-icons/md'),
        "ti": () => import('react-icons/ti'),
        "go": () => import('react-icons/go'),
        "fi": () => import('react-icons/fi'),
        "lu": () => import('react-icons/lu'),
        "gi": () => import('react-icons/gi'),
        "wi": () => import('react-icons/wi'),
        "di": () => import('react-icons/di'),
        "ai": () => import('react-icons/ai'),
        "bs": () => import('react-icons/bs'),
        "ri": () => import('react-icons/ri'),
        "fc": () => import('react-icons/fc'),
        "gr": () => import('react-icons/gr'),
        "hi": () => import('react-icons/hi'),
        "hi2": () => import('react-icons/hi2'),
        "si": () => import('react-icons/si'),
        "sl": () => import('react-icons/sl'),
        "im": () => import('react-icons/im'),
        "bi": () => import('react-icons/bi'),
        "cg": () => import('react-icons/cg'),
        "vsc": () => import('react-icons/vsc'),
        "tb": () => import('react-icons/tb'),
        "tfi": () => import('react-icons/tfi'),
        "rx": () => import('react-icons/rx'),
        "pi": () => import('react-icons/pi'),
        "lia": () => import('react-icons/lia'),
    };
      const [prefix, ...iconIdParts] = split;
      const iconId = iconIdParts.join('_');
      const importFn = importFns[prefix];
      if (importFn) {
        importFn().then(icons => {
          const Icon = icons[iconId];
          if (Icon) {
            setIcon(() => <Icon />);
          }
        });
      }
    }
  }, []);

  return text ? (
    <div className={css.sectionText}>
      {(!icon && heading) ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{icon} {content}</p>
    </div>
  ) : null;
};

export default SectionTextMaybe;
